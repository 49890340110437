import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Stil og tone",
  "order": 0,
  "template": "default",
  "introtext": "Vår måte å skrive og snakke på i SpareBank 1",
  "illustration": "woman-holding-glasses.svg"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const CheckList = makeShortcode("CheckList");
const CheckListItem = makeShortcode("CheckListItem");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Hel ved`}</h2>
    <p>{`Vi er til å stole på, holder det vi lover og er der også når det blåser. Vårt språk er ærlig og inkluderende, åpent og tydelig.`}</p>
    <div className="sb1ds-layout-2col-grid">
   <div>
      <h3 className="ffe-h4">Sånn:</h3>
      <CheckList mdxType="CheckList">
         <CheckListItem mdxType="CheckListItem">Tydelige på hva vi gjør og hva vi står for</CheckListItem>
         <CheckListItem mdxType="CheckListItem">Kommuniserer alt som skal være viktig for kunden</CheckListItem>
         <CheckListItem mdxType="CheckListItem">Inspirerer og gir ros til kunder når de tar gode valg, og veileder når de trenger det</CheckListItem>
      </CheckList>
   </div>
   <div>
      <h3 className="ffe-h4">Ikke sånn:</h3>
      <CheckList mdxType="CheckList">
         <CheckListItem isCross={true} mdxType="CheckListItem">Skylder på andre</CheckListItem>
         <CheckListItem isCross={true} mdxType="CheckListItem">Snakker bare om oss selv, hold heller fokus på kundenes behov</CheckListItem>
         <CheckListItem isCross={true} mdxType="CheckListItem">Skryter</CheckListItem>
      </CheckList>
   </div>
    </div>
    <h2>{`Jordnær`}</h2>
    <p>{`Vi er folkelige og vi er enkle å forstå. Vi snakker med en personlig tone, vi har et ubanksk språk og vi tør å vise vår personlighet.`}</p>
    <div className="sb1ds-layout-2col-grid">
   <div>
      <h3 className="ffe-h4">Sånn:</h3>
      <CheckList mdxType="CheckList">
         <CheckListItem mdxType="CheckListItem">Skriv og snakk enkelt</CheckListItem>
         <CheckListItem mdxType="CheckListItem">Bruk et personlig, vennlig og muntlig språk</CheckListItem>
         <CheckListItem mdxType="CheckListItem">Ha glimt i øyet og bruk gjerne lun humor</CheckListItem>
         <CheckListItem mdxType="CheckListItem">Snakk med folk, ikke til</CheckListItem>
         <CheckListItem mdxType="CheckListItem">Bruk alltid ord som «deg» og «dere» når vi snakker med kundene</CheckListItem>
      </CheckList>
   </div>
   <div>
      <h3 className="ffe-h4">Ikke sånn:</h3>
      <CheckList mdxType="CheckList">
         <CheckListItem isCross={true} mdxType="CheckListItem">Skriver et språk som ikke alle forstår</CheckListItem>
         <CheckListItem isCross={true} mdxType="CheckListItem">Bruker fremmedord og faguttrykk</CheckListItem>
         <CheckListItem isCross={true} mdxType="CheckListItem">Snakker ovenfra og ned</CheckListItem>
         <CheckListItem isCross={true} mdxType="CheckListItem">Er selvhøytidelig og jålete</CheckListItem>
         <CheckListItem isCross={true} mdxType="CheckListItem">Bruker upersonlige ord som «man»</CheckListItem>
         <CheckListItem isCross={true} mdxType="CheckListItem">Har stort fokus på oss selv, som «oss», «vi» og «SpareBank 1»</CheckListItem>
      </CheckList>
   </div>
    </div>
    <h2>{`Lokal`}</h2>
    <p>{`Vi er en bank som er tilstede og nær. Vi er stolte av stedet vi kommer fra og snakker om det folk og bedrifter får til her vi er.`}</p>
    <div className="sb1ds-layout-2col-grid">
   <div>
      <h3 className="ffe-h4">Sånn:</h3>
      <CheckList mdxType="CheckList">
         <CheckListItem mdxType="CheckListItem">Bruk dialekt og uttrykk</CheckListItem>
         <CheckListItem mdxType="CheckListItem">Fortell lokale historier</CheckListItem>
         <CheckListItem mdxType="CheckListItem">Bruk lokale bilder og film</CheckListItem>
         <CheckListItem mdxType="CheckListItem">Inkluder lokal kultur</CheckListItem>
         <CheckListItem mdxType="CheckListItem">Ha relevant kunnskap og innsikt om regionen</CheckListItem>
         <CheckListItem mdxType="CheckListItem">Bruk norske ord og navn</CheckListItem>
      </CheckList>
   </div>
   <div>
      <h3 className="ffe-h4">Ikke sånn:</h3>
      <CheckList mdxType="CheckList">
         <CheckListItem isCross={true} mdxType="CheckListItem">Kommuniserer upersonlig</CheckListItem>
         <CheckListItem isCross={true} mdxType="CheckListItem">Bruker irrelevante kontekster</CheckListItem>
         <CheckListItem isCross={true} mdxType="CheckListItem">Snakker distansert</CheckListItem>
         <CheckListItem isCross={true} mdxType="CheckListItem">Snakker om «hele landet» når vi ikke må</CheckListItem>
      </CheckList>
   </div>
    </div>
    <h2>{`Nyttig`}</h2>
    <p>{`Vi leter alltid etter de gode løsningene for hver enkelt kunde, slik at de kan få verdi ut av pengene sine hver dag.`}</p>
    <div className="sb1ds-layout-2col-grid">
   <div>
      <h3 className="ffe-h4">Sånn:</h3>
      <CheckList mdxType="CheckList">
         <CheckListItem mdxType="CheckListItem">Lytt. Hva er viktig for kunden?</CheckListItem>
         <CheckListItem mdxType="CheckListItem">Bruk spørreord aktivt</CheckListItem>
         <CheckListItem mdxType="CheckListItem">Hei på framskritt</CheckListItem>
         <CheckListItem mdxType="CheckListItem">Gi kunden forutsigbarhet og trygghet i valgene sine</CheckListItem>
      </CheckList>
   </div>
   <div>
      <h3 className="ffe-h4">Ikke sånn:</h3>
      <CheckList mdxType="CheckList">
         <CheckListItem isCross={true} mdxType="CheckListItem">Bruker overdrevne superlativer (mest, best osv)</CheckListItem>
         <CheckListItem isCross={true} mdxType="CheckListItem">Overselger våre produkter</CheckListItem>
         <CheckListItem isCross={true} mdxType="CheckListItem">Snakker over hodet på folk</CheckListItem>
      </CheckList>
   </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      